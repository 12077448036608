import axios from './axios.js'
// import url from './urls.js'

export default {
  // Projects
  // getProjects: (data) => axios.get(`${url.project}?${new URLSearchParams(data).toString()}`),
  // addProject: (data) => axios.post(url.project, data.params),
  // renameProject: (data) => axios.put(`${url.project}`, data.params)
  // deleteProjects: (data) => axios.delete(url.project, { data: data.params })

  newMaskedText: (data) => axios.post(process.env.VUE_APP_MASKING_API_ENDPOINT, data)
}
