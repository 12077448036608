import moment from 'moment'
import socketClient from 'socket.io-client'
import api from '@/apis'

export default {
  state: {
    ip_addr: null,
    session_id: null,
    socket_server: null
  },
  mutations: {
    INIT_SOCKET_SERVER(state, { socket_server, language }) {
      state.socket_server = socket_server

      // Auto register when connected to server
      state.socket_server.on('connect', () => {
        state.socket_server.emit('register', { ip_addr: state.ip_addr, language })
      })

      // Add event listener
      state.socket_server.on('register_result', (data) => {
        this.dispatch('socketRegisterResultListener', data)
      })
      state.socket_server.on('nlp_result', (data) => {
        this.dispatch('socketNLPResultListener', data)
      })
      state.socket_server.on('notification_result', (data) => {
        this.dispatch('socketNotificationResultListener', data)
      })
    },
    DISCONNECT_SOCKET_SERVER(state) {
      if (state.socket_server) state.socket_server.disconnect()
    },
    SET_SOCKET_SESSION_ID(state, payload) {
      state.session_id = payload
    },
    SET_SOCKET_IP_ADDRESS(state, payload) {
      state.ip_addr = payload
    }
  },
  actions: {
    updateSocketServer({ rootState, commit }) {
      try {
        // Disconnect from the previous server before connecting to new server
        commit('DISCONNECT_SOCKET_SERVER')

        // Connect To Socket Server
        const socket_server = socketClient(process.env.VUE_APP_SOCKET_SERVER)
        commit('INIT_SOCKET_SERVER', { socket_server, language: rootState.i18n.socket_language })
      } catch (error) {
        console.log('updateSocketServer', error.message)
      }
    },
    // Send message to Voicebot Socket
    async sendMessageToSocket({ state, commit }, { title, value }) {
      try {
        const ignore_words = ['_']
        const datetime = moment().format('hh:mm A')
        const ignore_word_check = ignore_words.filter((word) => title.includes(word))

        let display_text = title
        if (process.env.VUE_APP_ENABLE_MASKING) {
          const response = await api.newMaskedText({ text: title })
          if (response.data.success) display_text = response.data.data.masked_text
        }

        if (!ignore_word_check.length) commit('APPEND_CHAT_HISTORY', { type: 'user', datetime, messages: [{ type: 'text', text: display_text }] })
        commit('APPEND_CHAT_HISTORY', { type: 'ai', loading: true, datetime: moment().format('hh:mm A'), messages: [{}] })
        state.socket_server.emit('user_text', value)
      } catch (error) {
        console.log(error)
      }
    },
    sendAudioToSocket({ state }, audio) {
      state.socket_server.emit('user_speak', audio)
    },
    socketRegisterResultListener({ rootState, commit, dispatch }, payload) {
      const socket_language = rootState.i18n.socket_language
      const { success, message, data } = payload
      if (success) {
        commit('SET_SOCKET_SESSION_ID', data.session_id)
        commit('SET_CHAT_LOADING', false)
        dispatch('sendMessageToSocket', { title: `init_${socket_language}`, value: `init_${socket_language}` })
      } else console.log(message)
    },
    socketNLPResultListener({ commit }, payload) {
      const { messages, is_bottom_menu_show, bottom_menu, input_disabled, input_placeholder } = payload
      commit('RESET_CHAT_INPUT_BAR')
      if (bottom_menu) commit('INIT_CHAT_BOTTOM_MENU', { is_bottom_menu_show, bottom_menu })
      if (input_disabled) commit('SET_CHAT_INPUT_BAR', { input_disabled, input_placeholder })
      if (messages.length) commit('APPEND_CHAT_HISTORY', { type: 'ai', loading: false, datetime: moment().format('hh:mm A'), messages })
    },
    socketNotificationResultListener({ dispatch }, { data }) {
      dispatch('socketNLPResultListener', data)
    }
  }
}
