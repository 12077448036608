import axios from 'axios'
import store from '@/store'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_SERVER_ENDPOINT
})

// Request interceptor
instance.interceptors.request.use(
  (config) => {
    // Embed the auth token to each API request
    // if (!config.url.includes('/login') && !config.url.includes('/logout')) {
    // config.headers.access_token = store.state.auth.access_token
    // config.headers.auth_project_id = store.state.auth.auth_project_id

    // Auto Add device_id to all query
    // if (config.method === 'get' && !config.url.includes('/admin_panel/interface/devices')) {
    //   if (config.url.includes('?')) config.url += `&device_id=${store.state.auth.selected_device}`
    //   else config.url += `?device_id=${store.state.auth.selected_device}`
    // }
    // }
    return config
  },
  (error) => {
    console.error('error:', error)
    Promise.reject(error)
  }
)

// Response interceptor
instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.message.includes(401)) store.commit('RESET_AUTH_STATUS')
    console.error('error:' + error)
    return Promise.reject(error)
  }
)

export default instance
