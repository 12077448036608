import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import lang from '@/lang'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { 'zhHant-HK': lang['zhHant-HK'], zhHant: lang.zhHant, zhHans: lang.zhHans, en: lang.en },
    current: 'zhHant-HK'
  }
})
