import zhHant from 'vuetify/es5/locale/zh-Hant'

// Usage -> {{ $vuetify.lang.t('$vuetify.close') }} -> 关闭
export default {
  ...zhHant,
  __General: {
    submit: '提交'
  },
  __InitialLoading: {
    connecting: '連接中...'
  },
  __ChatBubbleAi: {
    answer_by_mrc: '此為人工智能生成的回答',
    mrc_answer_desc: '小慧運用人工智能學習了海關網頁上的資料並根據你的查詢為你建議最相關的頁面。',
    answer_by_chatGPT: 'ChatGPT 智能回答',
    chatgpt_answer_desc: 'ChatGPT 是一款由 OpenAI 開發的 AI 聊天機器人，透過自然語言處理技術生成模擬人類的對話內容。',
    prompt_bar_message: '以上的內容能幫到你嗎？',
    comment_dialog: {
      title: '能說說您給這個評分的原因嗎？',
      yes: '好的',
      no: '不用了',
      placeholder: '在此填寫內容',
      remaining_character: '剩餘字元: {0}/{1}',
      submit: '提交',
      closing: '感謝你的意見！'
    }
  },
  __Cards: {
    highly_relevant: '高度相關',
    moderately_relevant: '中等相關',
    slightly_relevant: '稍微相關',
    client_list: '客戶名單',
    success_cases: '成功案例'
  },
  __ChatContainer: {
    today: '今天'
  },
  __InputBar: {
    placeholder: '輸入訊息(唔好輸入任何個人資料呀!)',
    press_to_record: '按住錄音',
    recording: '錄音中...'
  },
  __Rating: {
    your_rating: '請評分',
    like: '喜歡',
    dislike: '不喜歡',
    field_placeholder: '能告訴我們您給這個評分的原因？',
    scale_rating_hint: '最高 5 分 ，最低 1 分'
  },
  __DateTime: {
    selected: '你已選擇',
    select_date: '請選擇日期',
    select_time: '請選擇時間'
  },
  __Dropdown: {
    select: '請選擇'
  }
}
