// import api from '@/apis'
export default {
  state: {
    loading: true,
    chat_history: [],

    input_type: 'text',
    bottom_menu: {
      visibility: false,
      type: null,
      buttons: []
    },

    input_bar: {
      disabled: false,
      placeholder: null,
      input_bar_mode: process.env.VUE_APP_INPUT_BAR_MODE
    }
  },
  mutations: {
    // Loading
    SET_CHAT_LOADING(state, payload) {
      state.loading = payload
    },

    // Chat History
    SET_CHAT_HISTORY(state, payload) {
      state.chat_history = payload
    },
    APPEND_CHAT_HISTORY(state, payload) {
      const { type, loading, messages } = payload // incoming messages
      const chat_history = state.chat_history
      const chat_history_length = chat_history.length
      const chat_history_last_index = chat_history_length - 1

      // Status for streaming text component, hardcode index[0] is because the streaming_text component will only triggered by notification and always come at 0 position
      const is_incoming_streaming_text = messages[0].type === 'streaming_text'
      const is_last_chatbubble_streaming_text = chat_history_length ? chat_history[chat_history_last_index].messages[0].type === 'streaming_text' : false

      // Append bubble to chat history
      if (type === 'user') state.chat_history.push(payload)
      else if (type === 'ai') {
        // Check if existing any loading bubble
        const loading_bubble_index = state.chat_history.findIndex((bubble) => bubble?.loading === true)
        // if there is any loading bubble existed, replace it with the new incoming message
        if (loading_bubble_index >= 0 && !loading) state.chat_history.splice(loading_bubble_index, 1, payload)
        else if (chat_history_length > -1 && is_incoming_streaming_text && is_last_chatbubble_streaming_text) {
          // Appending streaming content to streaming text component
          const { text, is_complete } = messages[0]
          state.chat_history[chat_history_last_index].messages[0].text += text
          state.chat_history[chat_history_last_index].messages[0].is_complete = is_complete
        } else state.chat_history.push(payload)
      }
    },
    REMOVE_CHAT_HISTORY(state, payload) {
      state.chat_history[payload.chat_i].messages.splice(payload.message_i, 1)
    },

    // Input Type
    SET_CHAT_INPUT_TYPE(state, payload) {
      state.input_type = payload
    },

    // Bottom Menu
    INIT_CHAT_BOTTOM_MENU(state, { is_bottom_menu_show, bottom_menu }) {
      const { type, buttons } = bottom_menu
      state.bottom_menu = { visibility: is_bottom_menu_show, type, buttons }
    },
    SET_CHAT_BOTTOM_MENU_VISIBILITY(state, payload) {
      state.bottom_menu.visibility = payload
    },

    // Input Bar
    SET_CHAT_INPUT_BAR(state, { input_disabled, input_placeholder }) {
      state.input_bar.disabled = input_disabled
      if (input_placeholder) state.input_bar.placeholder = input_placeholder
    },
    RESET_CHAT_INPUT_BAR(state) {
      state.input_bar.disabled = false
    }
  }
}
