// Module loader - auto load the module which meet the naming rule
const requireModule = require.context('./', true, /\.js$/)
const modules = {}

requireModule.keys().forEach((fileName) => {
  // Example:
  // Folder structure -> ./global/auth/login.js
  // rootFolder = global
  // masterFolder = auth
  // fileName = login.js
  const fileList = fileName.split('/')
  const masterFolder = fileList[fileList.length - 2]
  const has_indexjs = fileList[fileList.length - 1] === `index.js`
  const is_module = /\w/.test(masterFolder)

  if (is_module && has_indexjs) {
    const moduleName = masterFolder
    modules[moduleName] = {
      ...requireModule(fileName).default
    }
  }
})
export default modules
