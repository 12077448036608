// Import Vue core modules
import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'

// Import UI Framework Module
import vuetify from './plugins/vuetify'

// Import libraries
import axios from 'axios'

// Modules init
Vue.config.productionTip = false

// Socket Init
;(async () => {
  // Get IP address
  let ip = '127.0.0.1'
  try {
    const response = await axios.get(`https://ip.abapi.info/text?r=${new Date().getTime()}`, { headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' } })
    ip = response.data
  } catch (error) {
    console.error(`[Error - IP Address] cannot retrieve client ip address`)
  }
  store.commit('SET_SOCKET_IP_ADDRESS', ip)
})()

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
